export const dealGoods = function(goods) {
  goods.images = goods.img ? goods.img.split(",") : [];
};

export default {
  name: "供奉物品",
  url: "/admin/mini-worship-item", // rest接口表名
  query: "", // rest接口query参数
  formItemRowType: "flex",
  labelCol: { flex: "120px" },
  wrapperCol: { flex: "auto" },
  buttons: [
    {
      name: "设置",
      component: "a-button",
      props: {
        type: "primary",
      },
      value: null,
      style: {
        "margin-left": "8px",
      },
      listeners: {
        click() {
          this.$router.push({ name: "GoodsSet" });
        },
      },
    },
  ],
  searchKeyType: {
    name: 1,
  },
  queryForm: [
    {
      key: "name",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入物品名称查询",
      },
    },
  ],
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "物品名称", dataIndex: "name" },
      {
        title: "图片",
        dataIndex: "img",
        customRender: (text) => (text && text.split(",").length > 0 ? <s-img src={text.split(",")[0]} /> : "--"),
      },
      { title: "价格", dataIndex: "price", customRender: (text) => `¥ ${(text / 100).toFixed(2)}` },
    ];
  },
  // 新增/编辑查询表单
  items: [
    {
      key: "name",
      label: "物品名称",
      component: "a-input",
      props: {
        placeholder: "请输入物品名称",
      },
      rules: [{ required: true, message: "请输入物品名称", trigger: "blur" }],
      span: 12,
    },
    {
      key: "priceNumber",
      label: "价格",
      component: "a-input-number",
      props: {
        placeholder: "请输入价格",
        min: 0,
      },
      style: {
        width: "100%",
      },
      rules: [{ required: true, message: "请输入价格", trigger: "change" }],
      span: 12,
    },
    {
      key: "img",
      label: "图片",
      component: "form-upload",
      props: {
        limit: 9,
      },
      rules: [{ required: true, message: "请上传图片", trigger: "change" }],
    },
    {
      key: "info",
      label: "详情",
      component: "a-textarea",
      props: {
        placeholder: "请输入详情",
        rows: 4,
      },
      rules: [{ required: true, message: "请输入详情", trigger: "blur" }],
    },
  ],
  // 详情接口expand字段
  expand: "",
  init: {}, // 新增实体初始化字段
  // 详情渲染字段
  renderItems: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        label: "物品名称",
        key: "name",
      },
      {
        label: "图片",
        key: "img",
        img: true,
      },
      {
        label: "价格",
        slot: (entity) => <span>{`¥ ${(entity.price / 100).toFixed(2)}`}</span>,
      },
      {
        label: "详情",
        key: "info",
        textarea: true,
      },
    ];
  },
  beforeEdit: function(detail) {
    return new Promise((resolve) => {
      if (detail.price) {
        detail.priceNumber = detail.price / 100;
      }
      resolve(detail);
    });
  },
  beforeUpdate: function(form) {
    return new Promise((resolve) => {
      form.price = form.priceNumber * 100;
      resolve(form);
    });
  },
};
