<template>
  <div class="content md no-padding flex-box vertical">
    <div class="content-area">
      <div class="order-pay-info" v-if="order">
        <div v-show="pageOne">
          <div class="pay-info-piece">
            <div class="order-pay-title">
              <div class="pay-title-text">龛位</div>
            </div>
            <div class="pay-info-item">
              <div class="pay-info-label">供奉位置</div>
              <div class="pay-info-value">{{ order.showArea }} {{ num }}</div>
            </div>
            <div class="pay-info-item">
              <div class="pay-info-label">供奉时长</div>
              <div class="pay-info-value">{{ order.showTime }}</div>
            </div>
          </div>
          <div class="pay-info-piece">
            <div class="order-pay-title">
              <div class="pay-title-text">供奉信息</div>
            </div>
            <div class="pay-info-item" v-for="(d, i) in order.itemList" :key="i">
              <div class="pay-info-label">{{ d.label }}</div>
              <div class="pay-info-value">{{ d.value }}</div>
            </div>
          </div>
          <niche-order-form
            class="order-form"
            layout="vertical"
            :label-col="null"
            :wrapper-col="null"
            only-show-goods
            :step="1"
            ref="orderForm"
          ></niche-order-form>
        </div>
        <div class="pay-content" v-show="pageTwo">
          <div class="other-title">支付信息</div>
          <template v-if="form.goods && form.goods.length > 0">
            <div class="pay-info-piece">
              <div class="order-pay-title">
                <div class="pay-title-text">供奉物品</div>
              </div>
              <div class="pay-info-item text-right" v-for="g in form.goods" :key="g.id">
                <div class="pay-info-label">{{ g.name }} × {{ g.count }}</div>
                <div class="pay-info-value">{{ getShowPrice(g.count * g.price) }}</div>
              </div>
            </div>
            <div class="pay-info-item price-item">
              <div class="pay-info-label">总金额：</div>
              <div class="pay-info-value">{{ getShowPrice(form.totalPrice) }}</div>
            </div>
          </template>
          <niche-order-form :step="2" ref="previewForm"></niche-order-form>
        </div>
      </div>
    </div>
    <a-space class="footer">
      <a-button type="primary" @click="next" v-show="pageOne">下一步</a-button>
      <a-button type="primary" @click="prev" v-show="pageTwo">上一步</a-button>
      <a-button type="primary" @click="next" v-show="pageTwo">确认</a-button>
    </a-space>
  </div>
</template>

<script>
import { getUserWxId } from "../../common/js/storage";
import { clone, getKeyTitle, omit } from "../../common/js/tool";
import { getLightAreaText, getShowPrice, wishTypeList } from "./light";
import nicheOrderForm from "./niche-order-form";

export default {
  name: "NicheWorshipGoods",
  components: {
    nicheOrderForm,
  },
  data() {
    return {
      form: {},
      order: null,
      step: 1,
    };
  },
  computed: {
    status_id() {
      return this.$route.params.id;
    },
    district() {
      return this.$route.query.district;
    },
    id() {
      return this.$route.query.id;
    },
    num() {
      return this.$route.query.num;
    },
    pageOne() {
      return this.step == 1;
    },
    pageTwo() {
      return this.step == 2;
    },
  },
  created() {
    this.getNicheOrder();
  },
  methods: {
    getShowPrice,
    getNicheOrder() {
      if (this.status_id && this.id && this.district && this.num) {
        let url = `/admin/mini-worship-status/${this.status_id}?expand=order`;
        this.$axios(url, { noTempleFilter: true }).then((res) => {
          let order = res.order;
          if (order) {
            const start = new Date(order.start_time),
              end = new Date(order.end_time);
            order = {
              ...order,
              showArea: getLightAreaText(this.district),
              showTime: `${start.pattern("yyyy年M月d日")}-${end.pattern("yyyy年M月d日")}`,
              itemList: [
                {
                  label: "供奉人",
                  value: order.name,
                },
                {
                  label: "手机号码",
                  value: order.mobile,
                },
                {
                  label: "祈福方向",
                  value: getKeyTitle(wishTypeList, order.type),
                },
                {
                  label: "祈福寄语",
                  value: order.note,
                },
              ],
            };
          }
          this.order = order;
        });
      }
    },
    prev() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    next() {
      let step = this.step;
      if (step == 1) {
        // 下一步
        this.$refs.orderForm.submit().then((form) => {
          let goods = form.goods;
          if (goods?.length > 0) {
            this.form = clone(form);
            this.form.totalPrice = form.goods.reduce((acc, cur) => acc + cur.count * cur.price, 0);
            this.step += 1;
          } else {
            this.$message.warning("请选择供奉物品");
          }
        });
      } else if (step == 2) {
        // 确认
        this.$refs.previewForm.submit().then((form) => {
          Object.assign(this.form, form);
          this.insertOrder(this.form);
        });
      }
    },
    insertOrder(form) {
      let district = this.district,
        num = this.num;
      let data = omit(form, ["goods", "totalPrice", "bill", "processor"]);
      data.f_id = this.order.id;
      data.admin_id = getUserWxId();
      data.cat = 3;
      data.min_user_id = -2;
      data.status = 1;
      let price = 0;
      let goods = form.goods;
      if (goods.length > 0) {
        data.order_info = JSON.stringify({
          num,
          district,
          goods,
          bill: form.bill,
          processor: form.processor,
          showArea: getLightAreaText(district),
        });
        let item_info = goods.map((item) => {
          price += item.count * item.price;
          return { id: item.id, num: item.count };
        });
        data.item_info = JSON.stringify(item_info);
      }
      data.price = price;
      this.$axios({
        url: "/admin/mini-worship-order/add",
        method: "POST",
        data,
        noTempleFilter: true,
      }).then((res) => {
        if (res.error == 0) {
          this.$message.success("订单已创建");
          this.$router.push({
            name: "NicheOrderDetail",
            params: { id: res.order.id },
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content-area {
  flex: 1;
  min-height: 0;
  overflow: auto;
}
.pay-info-piece {
  padding: 10px 20px;
  width: 480px;
}
.order-pay-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  font-size: 16px;
}
.pay-title-text {
  font-weight: 600;
}
.pay-info-item {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  padding-left: 20px;
  &.text-right {
    .pay-info-value {
      text-align: right;
    }
  }
}
.pay-info-label {
  width: 5em;
}
.pay-info-value {
  flex: 1;
  min-width: 0;
}
.order-form {
  padding: 10px 20px;
}
.price-item {
  font-size: 16px;
  font-weight: 600;
}
.pay-content {
  padding: 16px;
}
</style>
